import { useDatabase } from "../../DatabaseProvider";
import { titleCase } from "./silcerUtils";

// Utility function
export const openAugmentManifest = (db, illicitType) => {
    const augments = db.data.augments;
    if (!augments) {
        console.error("No augments found in the database.");
        return;
    }

	// Group by skill, then by type, then sort by rank
	const groupedAugments = Object.values(augments).reduce((acc, augment) => {
		if (!acc[augment.skill]) {
			acc[augment.skill] = {
				passive: [],
				active: [],
				quantum: [],
			};
		}
		acc[augment.skill][augment.type]?.push(augment);
		return acc;
	}, {});

	// Sort each type array by rank
	Object.values(groupedAugments).forEach(skillGroup => {
		Object.keys(skillGroup).forEach(type => {
			skillGroup[type].sort((a, b) => a.rank - b.rank);
		});
	});

    // Generate HTML for the manifest
    const manifestContent = Object.entries(groupedAugments)
        .map(([skill, types]) => {
            const typeSections = Object.entries(types)
                .map(([type, augments]) => {
                    if (augments.length === 0) return "";

                    const augmentList = augments
                        .map((augment) => {
                            const { name, desc, rank, target, duration, illicit } = augment;
							if (illicit != illicitType)
                            {
                                return null;
                            }
                            return `
                                <strong>${name}</strong> (+${rank} ${titleCase(skill)} ${type === "quantum" ? `/ target: ${titleCase(target)}`: ""} ${duration ? " / "+titleCase(duration):""})
                                ${desc}<p>
                            `;
                        })
                        .join("");

                    return `
                        <h3>${titleCase(type)}</h3>
                        <ul>${augmentList}</ul>
                    `;
                })
                .join("");

            return `
                <h2>${titleCase(skill)}</h2>
                ${typeSections}
            `;
        })
        .join("");

    // Create new tab and inject manifest content
    const newTab = window.open();
    if (newTab) {
        newTab.document.write(`
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Augment Manifest</title>
                <link href="https://fonts.googleapis.com/css2?family=Galdeano&display=swap" rel="stylesheet">
                <style>
                    body {
                        font-family: 'Galdeano', sans-serif;
                        margin: 0;
                        padding: 20px;
                        line-height: 1.5;
                    }
                    h1, h2, h3 {
                        margin: 0;
                        padding: 10px 0;
                    }
                    ul {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                    }
                    li {
                        margin-bottom: 10px;
                        padding: 0;
                    }
                </style>
            </head>
            <body>
                <h1>Augment Manifest</h1>
                ${manifestContent}
            </body>
            </html>
        `);
        newTab.document.close();
    }
};

// Default React component (if needed)
const AugmentManifest = () => {
    const db = useDatabase();

    // Automatically open the manifest on mount (optional)
    React.useEffect(() => {
        openAugmentManifest(db);
    }, [db]);

    return null; // Nothing to render in DOM
};

export default AugmentManifest;
