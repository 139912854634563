import React from "react";
import "./AugmentPicker.css";
import { propertyDefs } from "../../PropertyDefs.js";
import { useDatabase } from "../../DatabaseProvider.jsx";
import SkillColumn from "./SkillColumn.jsx";
import { titleCase } from "./silcerUtils.jsx";

const AugmentPicker = ({ statblock, augmentPoints, onUpdate, scrollRefs, scrollPositions, legality }) => {
    const db = useDatabase();
    const augmentsOwned = statblock.augments || [];
    const skillLevels = statblock.skills || {};

    const renderAugments = (skillId) => {
        const skillAugments = Object.values(db.data.augments)
            .filter((augment) => augment.skill === skillId)
            .sort((a, b) => a.rank - b.rank);

        const groupedAugments = skillAugments.reduce((groups, augment) => {
            groups[augment.rank] = groups[augment.rank] || [];
            groups[augment.rank].push(augment);
            return groups;
        }, {});

        return Object.entries(groupedAugments).map(([rank, augments]) => {
            // Filter augments that match legality
            const validAugments = augments.filter(augment =>
                legality === undefined || legality == augment.illicit
            );

            // Skip rendering this rank group if there are no valid augments
            if (validAugments.length === 0) return null;

            return (
                <div key={rank} className="rank-group">
                    <div className="rank-header">Rank: {rank}</div>
                    {validAugments.map((augment) => {
                        const isOwned = augmentsOwned.includes(augment.id);
                        const remainingAP = augmentPoints.budget - augmentPoints.spend;
                        const augmentCost = augment.type == "quantum" ? augment.rank * 2 : augment.illicit != "legal" ? 0 : augment.rank;
                        const canEquip = skillLevels[skillId] >= augment.rank;
                        const isValid = isOwned || (canEquip && augmentCost <= remainingAP);
                        const typeClassMap = {
                            passive: "augment-button-passive",
                            active: "augment-button-active",
                            quantum: "augment-button-quantum",
                        };

                        let nameclass = "augment-name";
                        if (augment.illicit == "illicit") nameclass += " augment-illicit";
                        if (augment.illicit == "magnichor") nameclass += " augment-magnichor";

                        const buttonClass = `augment-button ${typeClassMap[augment.type] || ""} ${isOwned ? "owned" : isValid ? "" : "invalid"}`;

                        return (
                            <button
                                key={augment.id}
                                className={buttonClass}
                                onClick={() => onUpdate(augment.id)}
                                disabled={!isValid}
                            >
                                <div className={nameclass}>{augment.name}</div>
                                {augment.type == "quantum" && <div className="augment-desc augment-details">{titleCase(augment.target)} / {titleCase(augment.duration)}</div>}
                                <div className="augment-desc">{augment.desc}</div>
                            </button>
                        );
                    })}
                </div>
            );
        });
    };

    return (
        <div className="augment-editor">
            <div className="augment-grid">
                {propertyDefs.silcerSkill.map((skill) => (
                    <SkillColumn
                        key={skill.id}
                        skill={skill}
                        scrollRefs={scrollRefs}
                        scrollPositions={scrollPositions}
                        onScroll={(e, skillId) => (scrollPositions.current[skillId] = e.target.scrollTop)}
                        contentRenderer={renderAugments}
                    />
                ))}
            </div>
        </div>
    );
};

export default AugmentPicker;
