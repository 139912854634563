import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useDatabase } from '../../DatabaseProvider';
import './MentionManager.css'
import ButtonJump from '../Buttons/ButtonJump';

const MentionJumpComponent = ({ mention }) => 
{
	const db = useDatabase();

	// Dig out the appropriate entry title or label
	// URLs have custom label assigned.
	let label = mention.name;
	// internal links pull their label from the title of the linked entry
	const isFromDB = mention.categoryID != null;
	if (isFromDB)
	{
		const targetEntry = db.getPropertyByPath([mention.categoryID,mention.entryID]);
		label = targetEntry ? targetEntry.name : "undefined";
	}
	else
	{
		label += " 🔗";
	}

	var className = ''
	if (!isFromDB) { className +=' mention-button-readonly-url'; }
	return (
		<ButtonJump className={className} label={label} onClick={() => {isFromDB ? jumpToEntry(mention) : jumpToURL(mention)}} />
	);

	function jumpToEntry(mention)
	{
		const destEntry = { categoryID:mention.categoryID, entryID:mention.entryID, noteID:mention.noteID };
		db.setCurrentEntry(destEntry);
	}

	function jumpToURL(mention)
	{
		window.open(mention.url, '_blank');
	}
}

export default MentionJumpComponent