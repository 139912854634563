
export const titleCase = (str) => str && str.replace(/\b\w/g, char => char.toUpperCase());


export const statsData = {
    physical: {
        skills: ["strength", "coordination"],
        resilience: "integrity",
    },
    mental: {
        skills: ["insight", "engagement"],
        resilience: "focus",
    },
    social: {
        skills: ["leadership", "risk"],
        resilience: "standing",
    },
};

export const getSkillsByCategory = (category) => statsData[category]?.skills || [];
export const getStatBySkill = (skill) =>
    Object.entries(statsData).find(([_, { skills }]) => skills?.includes(skill))?.[0];
export const getResilienceByStat = (category) => statsData[category]?.resilience;
export const getResilienceBySkill = (skill) => {
    const category = getStatBySkill(skill);
    return category ? getResilienceByStat(category) : undefined;
};


export const calculateBaseStatWithAugments = (db, character, statName) => {
    const { statblock } = character;
    const { skills = {}, augments = [] } = statblock;

    // Get relevant skills for the statName
    const { skills: relevantSkills } = statsData[statName.toLowerCase()];

    const base = relevantSkills.reduce((sum, skill) => {
        const skillValue = parseInt(skills[skill.toLowerCase()] || "0", 10); // Convert skill value to number
        return sum + skillValue;
    }, 0);

    // Find passive augments applicable to the stat
    const passiveAugments = augments
        .map(augId => db.data.augments[augId])
        .filter(augment => {
            if (!augment || augment.type !== "passive") return false;
            return relevantSkills.includes(augment.skill.toLowerCase());
        });

    // Sum the ranks of all applicable passive augments
    const passiveBonus = passiveAugments.reduce((sum, augment) => {
        return sum + parseInt(augment.rank);
    }, 0);

    // Total stat value including bonuses
    const total = parseInt(base) + parseInt(passiveBonus);

    return {
        base,
        passiveBonus,
        total,
    };
};

export const getDieType = (rank) => {
	switch (rank) {
		case "1": return "1d4";
		case "2": return "1d6";
		case "3": return "1d8";
		case "4": return "2d4";
		case "5": return "2d6";
		case "6": return "2d10";
		default: return "1d4";
	}
};


export const rollInitiative = (characterID,db) => {
    const character = db.data.monsters[characterID];
    if (!character)
    {
        console.log("Can't find character", characterID);
        return 0;
    }

    // Get Action skills: Coordination, Engagement, Risk
    const actionSkills = ["coordination", "engagement", "risk"];
    const { skills, augments } = character.statblock;

    // Find the highest Action skill rank
    let highestSkill = null;
    let highestRank = 0;

    for (const skill of actionSkills) {
        const rank = parseInt(skills[skill.toLowerCase()] || "0", 10);
        if (rank > highestRank) {
            highestSkill = skill;
            highestRank = rank;
        }
    }

    if (!highestSkill || highestRank === 0) {
        console.error("No valid Action skills found for initiative.");
        return;
    }

    // Roll based on the highest skill's rank
	const die = getDieType(highestRank); // Example: "1d4" or "2d6"

	// Parse the die string (e.g., "1d4" -> 1 die with 4 sides, "2d6" -> 2 dice with 6 sides)
	const [numDice, dieFaces] = die.split("d").map(Number);

	let baseRoll = 0;
	// Roll each die and sum the results
	for (let i = 0; i < numDice; i++) {
		baseRoll += Math.floor(Math.random() * dieFaces) + 1;
	}

    // Calculate bonus from active and quantum augments
    const activeAugments = character.statblock.augments
        .map(augId => db.data.augments[augId])
        .filter(augment => {
            return augment
                && ["active", "quantum"].includes(augment.type)
                && augment.skill.toLowerCase() === highestSkill.toLowerCase();
        });

    const augmentBonus = activeAugments.reduce((sum, augment) => sum + parseInt(augment.rank || 0, 10), 0);

    // Final roll value
    const roll = baseRoll + augmentBonus;
    console.log(`${character.name} initiative roll: ${highestSkill} ${die} ${baseRoll} + ${augmentBonus} = ${roll}`);
    return roll;
}

export const silcerDiceRoller = (rollDef) => {
	// Die is array of diecount, diesize
	let count = rollDef[0];
	let die = rollDef[1];
    let total = 0;
    let rolls = [];
    let exploded = false;

    const rollDie = () => Math.floor(Math.random() * die) + 1;

    for (let i = 0; i < count; i++) {
        let roll = rollDie();
        rolls.push(roll);
        total += roll;

        // Exploding mechanic
        while (roll === die) {
            exploded = true;
            roll = rollDie();
            rolls.push(roll);
            total += roll;
        }
    }

    return {
        total,
        rolls,
        exploded,
        rollType: "exploding",
        die,
    };
};

export const getStatRendererProps = (statName, statsData, character, derivedStats, renderSkill) => {
    const { skills, resilience } = statsData[statName];

    return {
        key: statName,
        statName,
        statValue: derivedStats[statName],
        skills: skills.map(skill => [skill, character.statblock.skills[skill]]),
        resilienceLabel: resilience,
        resilienceMax: derivedStats[resilience],
        renderSkill,
    };
};

export const accumulateActiveAugmentsForSkill = (db, character, skill) => {
    return character.statblock.augments
        // Lookup augment by ID in the object
        .map(augmentId => db.data.augments[augmentId])
        // Active and Quantum both increase a matching skill roll
        .filter(augment => augment && (augment.type === "active" || augment.type === "quantum") && augment.skill === skill)
        // Calculate the sum of the power
        .reduce((sum, augment) => sum + parseInt(augment.rank), 0);
};

// Given the rank of an attacker's skill and their active augment bonus and a defender's Stat value
// determine how much resilience, on active, should be lost per roll
export const calculateAverageResilienceLoss = (rank, augmentBonus, defenderDT) => {
    // Define the die size based on rank
    const dieSize = [4, 6, 8, 10, 12, 20][rank - 1];
    if (!dieSize) {
        throw new Error("Invalid rank provided. Rank must be between 1 and 6.");
    }

    // Recursive function to calculate probabilities with exploding dice
    const getExplodingProbability = (currentSum, target, dieSides) => {
        if (currentSum >= target) return 1; // Already met the target
        const remaining = target - currentSum;

        // Calculate probabilities for each face of the die
        const faceProbabilities = Array.from({ length: dieSides }, (_, i) => i + 1)
            .map(face => (face >= remaining ? 1 / dieSides : 0)) // Successes for this roll
            .reduce((sum, prob) => sum + prob, 0);

        // Recursive explosion handling
        const explosionProbabilities = (1 / dieSides) * getExplodingProbability(0, remaining, dieSides);

        return faceProbabilities + explosionProbabilities;
    };

    // Calculate expected resilience loss
    const calculateLoss = (DT, dieSides, bonus) => {
        let expectedLoss = 0;

        for (let overage = 0; ; overage++) {
            const adjustedDT = DT + overage * 5 - bonus; // Adjust DT for overages and augment bonus
            const probability = getExplodingProbability(0, adjustedDT, dieSides);

            if (probability <= 0) break; // No further probabilities contribute

            expectedLoss += probability * (1 + overage); // Add weighted loss based on overage
        }

        return expectedLoss;
    };

    // Call the function with the provided inputs
    return calculateLoss(defenderDT, dieSize, augmentBonus);
};
