import { titleCase } from "./silcerUtils";
import "./SilcerStatBlock.css";

export const DiceRendererComponent = ({rollDef}) => {
    const { name, total, exploded, silcer, die, mod } = rollDef;
    const outcome = rollDef.silcer.outcome;
    const resultStyle = exploded ? "dice-exploded" : "dice-normal";
    let symbol = mod > 0 ? " + " : " ";
    let bonus = mod == 0 ? "" : symbol + mod;
    return (
        <div className="dice-renderer">
            <strong>{titleCase(name)}</strong>:{" "}
            <div className="simple-column dice-details">
				<div>{die[0]}d{die[1]}{bonus}</div>{" "}
                <div>[{silcer.dice.join(" + ")}]</div>
            </div>
            <span className={resultStyle}>{total}</span>{" "}
            {outcome}
        </div>
    );
};