import React from "react";
import { titleCase } from "./silcerUtils";

const StatRenderer = ({ 
    statName, 
    statValue, 
    skills, 
    resilienceLabel, 
    resilienceValue, 
    resilienceMax, 
    renderSkill, 
    onIncrementResilience, 
    onDecrementResilience 
}) => {
    
    return (
        <div className="stat-section">
            <div className="stat-header">
                <span>{titleCase(statName)}</span>
                <span className="stat-header-number">{statValue}</span>
            </div>
            {skills.map(([skillName, skillRank]) => renderSkill({skill:skillName, rank:skillRank}))}
            <div className="resilience-value">
				<span className="resilience-number">{resilienceMax}</span>
				<span className="resilience-label">{titleCase(resilienceLabel)}</span>
            </div>
        </div>
    );
};

export default StatRenderer;
