import React, { useState, useContext, useRef } from "react";
import { useDatabase } from "../../DatabaseProvider";
import "./SilcerStatBlock.css";
import { RollHistoryContext } from "../../contexts/RollHistory/RollHistoryContext";
import AugmentPicker from "./AugmentPicker";
import StatRenderer from "./StatRenderer";
import AugmentsRow from "./AugmentsRow";
import { openAugmentManifest } from "./AugmentManifest";
import { openArchetypeManifest } from "./ArchetypeManifest";
import { openCharacterSheet } from "./PrintableCharacterSheet";
import { calculateBaseStatWithAugments, getResilienceBySkill, getStatBySkill, getStatRendererProps, statsData, titleCase } from "./silcerUtils";
import { renderCharacterDetails, renderEditableSkill, renderRollableSkill } from "./silcerRender";
import ArchetypePicker from "./ArchetypePicker";
import { DiceRendererComponent } from "./DiceRenderComponent";
import FullScreenModal from "../Modal/FullScreenModal";
import SearchableDropdown from "../SearchableDropdown/SearchableDropdown";
import { propertyDefs } from "../../PropertyDefs";
import { generateDropdownOptions } from "../Entry/generateDropdownOptions";

const SilcerStatBlock = ({ pathObj, sendResultToHistory, characters }) => {
    const db = useDatabase();
    const { addRoll } = useContext(RollHistoryContext);
    const characterPath = [...pathObj.path];
    const character = db.getPropertyByPath(characterPath);
    const scrollRefs = useRef({});
    const scrollPositions = useRef({});

    const [resilienceValues, setResilienceValues] = useState(() => ({
        integrity: character.statblock?.resiliences?.integrity,
        focus: character.statblock?.resiliences?.focus,
        standing: character.statblock?.resiliences?.standing,
    }));

    const [diceRollResult, setDiceRollResult] = useState(null);
    const [editingAugments, setEditingAugments] = useState(false);
    const [editingArchetype, setEditingArchetype] = useState(false);
    const [augmentLegality, setAugmentLegality] = useState("legal");
    const [isModalOpen, setModalOpen] = useState(false);
    const [characterListButtons, setCharacterListButtons] = useState([]); 
    const [currentRollDef, setCurrentRollDef] = useState(null);

    if (!character?.statblock?.skills) return null;
    if (!character?.statblock?.resiliences) return null;
    
    // Reset scroll positions when switching pickers
    const resetScrollPositions = () => {
        scrollPositions.current = {};
    };

    const handleSetAugmentsLegality = (value) => {
        console.log(value)
        setAugmentLegality(value);
    };

    // Reset scroll when toggling
    const handleSetEditingAugments = (value) => {
        if (!editingAugments && value) resetScrollPositions(); 
        setEditingAugments(value);
        if (value) setEditingArchetype(false);
    };

    const handleSetEditingArchetype = (value) => {
        if (!editingArchetype && value) resetScrollPositions();
        setEditingArchetype(value);
        if (value) setEditingAugments(false);
    };
    
    function calculateAugmentBudget(level) {
        // Invalid level
        if (level < 1) return 0; 
        // Starting points at level 1
        let augmentPoints = 3; 
        for (let n = 2; n <= level; n++) {
            augmentPoints += Math.floor((n - 2) / 2) + 2;
        }

        return augmentPoints;
    }

    const calculateDerivedStat = (character, db) => {
        const skills = character.statblock.skills;
        const augmentIds = character.statblock.augments;
        const augments = augmentIds.map((id) => db.data.augments[id]).filter(Boolean);

        // Calculate character level
        const totalSkillRanks = Object.values(skills).reduce((sum, rank) => sum + parseInt(rank, 10), 0);
        const level = totalSkillRanks - 10;

        // Calculate augmentBudget based on level
        const augmentBudget = calculateAugmentBudget(level);

        // Calculate AugmentSpend by iterating through augments
        const augmentSpend = augments.reduce((total, augment) => {
            const rank = parseInt(augment.rank, 10);
			// Skip invalid ranks
			if (isNaN(rank)) return total;
			// Skip illicit 
			if (augment.illicit != "legal") return total;
            return total + (augment.type === "quantum" ? rank * 2 : rank);
        }, 0);

        // Helper function: Get passive bonuses
        const getPassiveBonus = (relevantSkills) =>
            augments
                .filter((augment) => augment.type === "passive" && relevantSkills.includes(augment.skill))
                .reduce((sum, augment) => sum + parseInt(augment.rank, 10), 0);

        // Calculate derived stats
        const derivedStats = Object.entries(statsData).reduce((acc, [statName, { skills, resilience }]) => {
            const statBonus = getPassiveBonus(skills);
            const statValue = skills.reduce((sum, skill) => {
                const skillBase = parseInt(character.statblock.skills[skill] || 0, 10); // Raw skill value
                return sum + skillBase; // Only add raw skill values
            }, 0) + statBonus;

            acc[statName] = statValue; // Store the derived stat value
            acc[resilience] = statValue + level; // Calculate resilience for the stat
            return acc;
        }, {});

        const augmentPoints = {budget: augmentBudget, spend: augmentSpend };
        // Add level, augmentBudget, and augmentSpend for completeness
        return { ...derivedStats, Level: level, augmentPoints: augmentPoints };
    };

    const derivedStats = calculateDerivedStat(character, db);

    const updateArchetype = (archetypeID) => {
        // Retrieve the archetype from the database
        const selectedArchetype = db.data.archetypes[archetypeID];

        if (!selectedArchetype) {
            console.error(`Archetype with ID "${archetypeID}" not found in database.`);
            return;
        }

        // Update the archetype ID in the character's statblock
        db.updateDB([...characterPath, "statblock", "archetype"], archetypeID);

        // Update the skills in the character's statblock
        const skills = {
            strength: selectedArchetype.strength,
            insight: selectedArchetype.insight,
            leadership: selectedArchetype.leadership,
            coordination: selectedArchetype.coordination,
            engagement: selectedArchetype.engagement,
            risk: selectedArchetype.risk,
        };
        db.updateDB([...characterPath, "statblock", "skills"], skills);
    };

    const updateAugments = (augmentID) => {
        const currentAugments = character.statblock.augments || [];

        let updatedAugments;
        if (currentAugments.includes(augmentID)) {
            // If the augment is already owned, remove it
            updatedAugments = currentAugments.filter((id) => id !== augmentID);
        } else {
            // Otherwise, add it
            updatedAugments = [...currentAugments, augmentID];
        }

        // Update the database
        db.updateDB([...characterPath, "statblock", "augments"], updatedAugments);
    };

    const updateSkillRank = (skill, newValue, db) => {

        const updatedSkills = {
            ...character.statblock.skills,
            [skill]: newValue,
        };

        const updatedDerivedStats = calculateDerivedStat(character, db);
        const resilienceName = getResilienceBySkill(skill);

        if (resilienceName) {
            const updatedResilience = updatedDerivedStats[resilienceName];

            setResilienceValues((prev) => ({
                ...prev,
                [resilienceName]: updatedResilience,
            }));

            db.updateDB([...characterPath, "statblock", "resiliences"], {
                ...character.statblock.resiliences,
                [resilienceName]: updatedResilience,
            });
        }

        db.updateDB([...characterPath, "statblock", "skills"], updatedSkills);
    };

    const renderAugmentEditor = () => {
        if (!db.editMode) return null;

        const renderDropdown = (label, onClick, condition = true) =>
            condition && (
                <SearchableDropdown 
                    key={label} 
                    className={"augment-button"}
					// pathObj={propertyDefs.legality}
					label={label}
					initialValue={augmentLegality}
                    options={generateDropdownOptions({ propertyDefArray: propertyDefs.legality })}
					onUpdateValue={onClick}
					propDef={{placeholder:"action type"}}
				/>
            );
            
        const renderButton = (label, onClick, condition = true) =>
            condition && (
                <button className="augment-button" onClick={onClick}>
                    {label}
                </button>
            );

        return (
            <>
                <div className="simple-row gap15">
                    {renderButton("Close Archetype Picker", () => handleSetEditingArchetype(false), editingArchetype)}
                    {renderButton("Close Augment Picker", () => handleSetEditingAugments(false), editingAugments)}
                    {!editingArchetype && !editingAugments && (
                        <>
                            {renderButton("Pick Archetype", () => handleSetEditingArchetype(true))}
                            {renderButton("Pick Augments", () => handleSetEditingAugments(true))}
                            {renderButton("Open Character Sheet", () =>
                                openCharacterSheet(db, character, derivedStats, resilienceValues)
                            )}
                            {renderButton("Augment Manifest", () => openAugmentManifest(db,"legal"))}
							{renderButton("Illicit Augments", () => openAugmentManifest(db, "illicit"))}
							{renderButton("Magnichor Relics", () => openAugmentManifest(db,"magnichor"))}
                            {renderButton("Open Archetype Manifest", () => openArchetypeManifest(db))}
                        </>
                    )}
                    {renderDropdown("Category", (e,v) => handleSetAugmentsLegality(v), editingAugments)}
                </div>
                <div>
                    {editingAugments && (
                        <AugmentPicker
                            statblock={character.statblock}
                            onUpdate={updateAugments}
                            scrollRefs={scrollRefs}
                            scrollPositions={scrollPositions}
                            augmentPoints={derivedStats.augmentPoints}
                            key="augment-editor"
                            legality={augmentLegality}
                        />
                    )}
                    {editingArchetype && (
                        <ArchetypePicker
                            statblock={character.statblock}
                            onUpdate={updateArchetype}
                            scrollRefs={scrollRefs}
                            scrollPositions={scrollPositions}
                            key="archetype-editor"
                        />
                    )}
                </div>
            </>
        );
    };

    const updateModel = (newModel) => {
        db.updateDB([...characterPath, "statblock", "model"], newModel);
    };

    // Triggered when a skill is clicked
    const passRollToCharacterList = (rollDef) => {
        // Create character buttons dynamically
        // If characters we passed into the stat block, those are the pre-selected foes, and their db is .monster 
        // (.id for encounter character is unique for that one encounter)
        const characterButtons = Object.values(characters||db.data.monsters).map((targetChar) => ({
            id: targetChar.monster || targetChar.id,
            label: targetChar.name,
        }));

        // Store the roll result
        setCurrentRollDef(rollDef);
        // Populate modal buttons
        setCharacterListButtons(characterButtons); 
        // Open modal
        setModalOpen(true);
    };

    // Apply roll result to the selected character
    const skillRollVsTargetCharacter = (charID) => {
        const targetCharacter = db.getPropertyByPath(["monsters", charID]);

        if (!targetCharacter) {
            console.error(`Character with ID ${charID} not found.`);
            return;
        }

        // Find the matching resilience for the rollDef skill
        const skillUsed = currentRollDef.silcer.skill;
        let matchingStat = null;

        
        for (const [stat, { skills }] of Object.entries(statsData)) {
            if (skills.includes(skillUsed)) {
                matchingStat = stat;
                break;
            }
        }

        const targetDifficulty = calculateBaseStatWithAugments(db, targetCharacter, matchingStat);

        let outcome = "";
        const resilience = getResilienceBySkill(skillUsed);

        if (currentRollDef.total < targetDifficulty.total)
        {
            // missed
            outcome = `  |  Missed ${targetCharacter.name}`;// ${titleCase(resilience)} ${targetDifficulty.total}`;
        }
        else
        {
            const difference = currentRollDef.total - targetDifficulty.total;

            if (!resilience) {
                console.error(`No matching resilience found for skill: ${skillUsed}`);
                return;
            }

            const damage = Math.floor(difference / 5) + 1;
            // const updatedStats = { ...targetCharacter.statblock.resiliences };
            // const from = parseInt(targetCharacter.statblock.resiliences[resilience]);
            // Apply damage: -1 resilience for every 5 points of rollDef total
            // const to = from - damage;
            // Update the target character's resilience in the database
            // updatedStats[resilience] = to; 
            // db.updateDB(["monsters", charID, "statblock", "resiliences"], updatedStats);

            outcome = `  |  +${difference} against ${targetCharacter.name} ${titleCase(matchingStat)}, ${titleCase(resilience)} -${damage}`; // to ${to}
        }

        currentRollDef.silcer.outcome = outcome;
        setDiceRollResult(currentRollDef);

        // Close the modal
        setModalOpen(false);
    };

    const renderSkill = (skillDef) => {
        if (db.editMode) {
            return renderEditableSkill({
                skillDef,
                onUpdateSkillRank: (skill, newValue) => updateSkillRank(skill, newValue, db),
            });
        } else {
            return renderRollableSkill({
                skillDef,
                character,
                db,
                resilienceValues,
                onRollResult: (rollDef) => {
                    if (!sendResultToHistory) {
                        setDiceRollResult(rollDef);
                    } else {
                        addRoll(rollDef);
                    }
                },
                onTargetedRoll: passRollToCharacterList,
            });
        }
    };
    return (
        <>
            <FullScreenModal
                isOpen={isModalOpen}
                buttons={characterListButtons}
                onClose={() => setModalOpen(false)}
                onButtonClick={(charID) => skillRollVsTargetCharacter(charID)}
            />

            <div className="silcer-stat-block">
                <div className="stat-block-banner">
                    {Object.keys(statsData).map((statName) => (
                        <StatRenderer
                            {...getStatRendererProps(
                                statName,
                                statsData,
                                character,
                                derivedStats,
                                renderSkill
                            )}
                        />
                    ))}
                </div>
                {renderAugmentEditor()}
                <div className="char-and-roll">
                    {renderCharacterDetails(db, derivedStats.Level, derivedStats.augmentPoints, updateModel, character)}
                    <AugmentsRow statblock={character.statblock} augmentsData={db.data.augments} />
                </div>
                {diceRollResult && <DiceRendererComponent rollDef={diceRollResult} />}
            </div>
        </>
    );
};

export default SilcerStatBlock;