import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { categoryList } from './PropertyDefs';
import { generateUniqueID } from './UniqueID';
import { smartLog } from './util/smartLog';

const DatabaseContext = createContext();

const API_URL = `${import.meta.env.VITE_API_URL}`

export const useDatabase = () => {
    return useContext(DatabaseContext);
};

export const DatabaseProvider = ({ children, filename}) => 
{
	const [editMode, setEditMode] = useState(false);
	const [data, setDatabase] = useState(null);
	const [isPortrait, setIsPortrait] = useState(
		window.innerHeight > window.innerWidth
	);

	const reopenEntryRef = useRef();

	// Not saved to database, this is the current category/entry in the editor 
	// currentEntry = {"categoryID":"1","entryID":"2","noteID":"3"}
	const [currentEntry, setCurrentEntry] = useState({});

	useEffect(()=>
	{ 
		if (reopenEntryRef.current)
		{
			// Set and reset
			setCurrentEntry(reopenEntryRef.current);
			reopenEntryRef.current = null;
		}
		if (data) { smartLog({cat:"db"},data); }
	},[data])

	useEffect(() => {
		window.addEventListener('resize', updateOrientation);
		window.addEventListener('orientationchange', updateOrientation);
	
		// Clean up listeners
		return () => {
			window.removeEventListener('resize', updateOrientation);
			window.removeEventListener('orientationchange', updateOrientation);
		};
	}, []);

	const applyOverridesDirectly = (originalList, overrides) => {
		overrides.forEach(({ path, value }) => {
			let target = originalList;

			// Traverse the path to find the target
			for (let i = 0; i < path.length - 1; i++) {
				const key = path[i];
				if (Array.isArray(target)) {
					target = target.find(item => item.id === key);
				} else {
					target = target[key];
				}
				if (!target) {
					console.warn(`Path ${path.join('.')} not found in categoryList.`);
					return;
				}
			}

			const lastKey = path[path.length - 1];

			if (Array.isArray(target)) {
				const parent = target.find(item => item.id === lastKey);
				if (parent) {
					Object.assign(parent, value); // Apply the override
				} else {
					console.warn(`Key ${lastKey} not found in categoryList.`);
				}
			} else if (target && typeof target === 'object') {
				target[lastKey] = { ...target[lastKey], ...value }; // Apply the override
			} else {
				console.warn(`Cannot set value at path ${path.join('.')}.`);
			}
		});
	};

    useEffect(() => {
        const fetchData = async () => {
            try {
				const endpoint = `${API_URL}/api/data?filename=${filename}`;
				// console.log("endpoint",endpoint)
                const response = await fetch(endpoint);
				const jsonData = await response.json();
				// Apply any overrides to PropertyDefs
				if (jsonData.categoryListOverrides)
				{
					// Apply each to the matching location in the database
					applyOverridesDirectly(categoryList, jsonData.categoryListOverrides);
				}

				smartLog({cat:"db save"},"Loaded",filename,jsonData);
                setDatabase(jsonData);
            } catch (error) {
                console.error('Error fetching JSON data:', error);
            }
        };

        fetchData();
    }, [filename]);

	function updateOrientation() {
		setIsPortrait(window.innerHeight > window.innerWidth);
	}

	const updateValueByPath = (path, value) => {
		const newData = {...data};
		// Set value in this new copy
	
		// Provided the raw pathObj, we might have to append the propertyID onto the path
		if (!path.propertyID)
		{
			// console.error("Can't build the path from",path);
		}
		else
		{
			path = [...path.path, path.propertyID];
		}
		setPropertyByPath(newData,path,value);
		smartLog({cat:"db set"},"updateValueByPath","path",path,"value",value);
		setDatabase(newData);
	};

	// Provide the database and a wrapped updateDB function that uses updateValueByPath
	const updateDB = (path, value, reopenEntry) => 
	{
		reopenEntryRef.current = reopenEntry;
		// console.log("Will reopen",reopenEntryRef.current)
		if (!path)
		{
			// Without path, that value had better be a copy of the entire database
			smartLog({cat:"db set"},"updateDB (entire)","value",value);
			setDatabase(value);
		}
		else
		{
			smartLog({cat:"db update"},"updateDB","path",path,"value",value);
			updateValueByPath(path, value);
		}
	};

	function setPropertyByPath(obj, pathArray, value) {
		const lastKeyIndex = pathArray.length - 1;
	
		// Traverse the object up to the second-to-last key
		const lastObj = pathArray.reduce((acc, key, index) => {
			if (index === lastKeyIndex) return acc; // Stop before the last key
			if (acc[key] === undefined) acc[key] = {}; // Create an empty object or array if the key doesn't exist
			return acc[key];
		}, obj);
	
		const lastIndex = pathArray[lastKeyIndex];
		
		// If the last object is an array and the last index is within range
		if (Array.isArray(lastObj) && lastIndex < lastObj.length) {
			if (value === null) {
				// Remove the element at lastIndex
				lastObj.splice(lastIndex, 1);
			} else {
				// Replace the element at lastIndex
				lastObj[lastIndex] = value;
			}
		} else if (!Array.isArray(lastObj)) {
			if (value === null) {
				delete lastObj[lastIndex];
			} else {
				lastObj[lastIndex] = value;
			}
		}
	}	

	function getPropertyByPath(pathArray,obj=data) {
		return pathArray.reduce((acc, key) => {
		  return (acc && typeof acc === 'object') ? acc[key] : undefined;
		}, obj);
	}

	function addMonsterToEncounter(monsterID, encounterID)
	{
		const newData = {...data};
		const encounter = newData.encounters[encounterID];
		const monsterDef = newData.monsters[monsterID];
		if (!monsterDef) 
		{
			console.log("Can't find",monsterID);
			console.log(newData.monsters)
		}
		const newCharacter = {
			"id": generateUniqueID(),
			"monster": monsterID,
			"name": monsterDef.name,
			"init": 0,
			"hp": monsterDef.statblock.hp,
			"maxhp": monsterDef.statblock.hp,
			"ac": monsterDef.statblock.ac
		}
		if (data.gameSystem == "silcer")
		{
			newCharacter.integrity = monsterDef.statblock?.resiliences?.integrity;
			newCharacter.focus = monsterDef.statblock?.resiliences?.focus;
			newCharacter.standing = monsterDef.statblock?.resiliences?.standing;
		}
		encounter.characters.push(newCharacter);
		updateDB(null,newData);
		setTimeout(() => {
			setCurrentEntry({ "categoryID": "encounters", "entryID": encounterID });			
		}, 10);
	}

	function addEncounter(encounterDef)
	{
		const newData = {...data};
		newData.encounters[encounterDef.id] = encounterDef;
		const newLM = {...newData.listManagers};
		addToListManager(newLM,"encounters",encounterDef.id)
		updateDB(null,newData);
		// Give it a frame to update, then select the new entry
		setTimeout(() => {
			setCurrentEntry({ "categoryID": "encounters", "entryID": encounterDef.id });			
		}, 10);
	}

	function addExistingRollToMonster(pathObj,rollID)
	{
		// Find the def
		const monster = data[pathObj.path[0]][pathObj.path[1]];
		// Roll list is just the ID
		if (!monster.rolls) { monster.rolls = [];}
		monster.rolls.push({id:rollID});
		updateDB(pathObj,monster.rolls)
	}

	function addRollToMonster(pathObj,rollDef)
	{
		if (!rollDef.action) { rollDef.action = "undefined" }
		const newData = {...data};
		const monster = newData[pathObj.path[0]][pathObj.path[1]];
		if (!monster.rolls) { monster.rolls = []; }
		monster.rolls.push({id:rollDef.id});
		const newLM = {...newData.listManagers};
		// Add the roll to the monster's list
		newData["rolls"][rollDef.id] = rollDef;
		addToListManager(newLM,"rolls",rollDef.id);
		// We modified both the rolls array and listMan, so overwrite entire DB with its changes
		updateDB(null,newData);
	}

	function addToListManager(newLM,category,id)
	{
		// Add the roll into the rolls list manager, at the root
		newLM[category].unshift({id:id,parentID:"rootOrder"});
		// And include this roll in the rootOrder's child list
		let rootArray = newLM[category].find(o=>o.id==="rootOrder")
		if (!rootArray) 
		{
			console.log("Creating first rootArray for rolls") 
			rootArray = {id:"rootArray",children:[]};
			newLM[category].unshift(rootArray);
		}
		rootArray.children.unshift(id);
	}

	// Since we might add multiple rolls and notes at the end of monster import, do it all in one update
	function addRollsAndNotesToMonster(pathObj,rollDefArray,note)
	{
		// Append to rolls list for that monster
		const newData = {...data};
		const monster = newData[pathObj.path[0]][pathObj.path[1]];
		if (!monster.rolls) { monster.rolls = []; }
		
		const newLM = {...newData.listManagers};
		rollDefArray.forEach((rollDef) => {
			// Add the roll to the monster's list
			monster.rolls.push({id:rollDef.id});
			// Add the roll to the rolls category
			newData["rolls"][rollDef.id] = rollDef;
			addToListManager(newLM,"rolls",rollDef.id);
		})

		// Add any leftover properties as a note
		if (!monster.notes) { monster.notes = {}; }
		// Upgrade to named object if it was an old array type
		if (Array.isArray(monster.notes)) { monster.notes = {}; }
		const newNote = {
			id:generateUniqueID(),
			"name":"Stats",
			"txt":note,
			"mentions":[]
		}
		monster.notes[newNote.id] = newNote;
		// And include it in the note order:
		const noteOrder = monster.notes.noteOrder;
		if (!noteOrder)
		{
			monster.notes.noteOrder = {ids:[newNote.id]}
		}
		else
		{
			noteOrder.ids.push(newNote.id)
		}

		newData[pathObj.path[0]][pathObj.path[1]] = monster;
		console.log("addRollsAndNotesToMonster\n",pathObj,"\n",rollDefArray,"\n",monster.notes,"\n",newData)
		// Overwrite the whole thing since we're affecting multiple categories
		updateDB(null,newData)
	}

	function removeRollFromMonster(pathObj,rollID)
	{
		// Append to rolls list for that monster
		const newData = {...data};
		const monster = newData[pathObj.path[0]][pathObj.path[1]];
		monster.rolls = monster.rolls.filter(item => item.id !== rollID);
		updateDB(pathObj.path,monster)
	}

	function addMention(path,mention)
	{
		// Mention list is one of the few arrays remaining. Consider converting it to named objects using their ids.
		const mentionList = getPropertyByPath(path);
		const updatedMentionList = [...mentionList];
		updatedMentionList.push(mention);
		smartLog({cat:"db update"},"addMention","path",path,"value",mention);
		updateValueByPath(path,updatedMentionList);
	}

	function addEntry(catid)
	{
		// Find the default entry schema from propertyDefs
		const categoryDef = categoryList.find(o=>o.id===catid);
		if (!categoryDef)
		{
			console.warn("Can't find category",catid,"to add new entry")
			return;
		}

		// Add a generated id to the default defined in category def
		const newEntry = { id: generateUniqueID(), ...JSON.parse(JSON.stringify(categoryDef.default))};
		newEntry.id = generateUniqueID();
		// Build it into a fresh copy of the database
		const newData = JSON.parse(JSON.stringify(data));
		newData[catid][newEntry.id] = newEntry;
		// And apply it to the listManager so we know how to sort it
		const newListManager = JSON.parse(JSON.stringify(newData.listManagers));
		// Add a new blank entry to the top of the array, child of the root
		const listitem = {id:newEntry.id, parentID:"rootOrder"}
		newListManager[catid].unshift(listitem);
		// And add this newEntry to the root
		const rootObject = newListManager[catid].find(o=>o.id==="rootOrder");
		if (!rootObject) { console.error("Why isn't there a rootObject in",catid); }
		if (!rootObject.children) { rootObject.children = [];}
		rootObject.children.unshift(newEntry.id);
		newData.listManagers = newListManager;
		setDatabase(newData);
		return newEntry;
	}

	// Update ALL the list managers at once
	function updateListManagers(lm)
	{
		// Don't let listManagers update during v1 -> v2 transition
		// They rely on the object form of the database instead of the legacy array
		// They'll get cleaned up as soon as the version increases to 2
		if (data.version > 1)
		{
			updateDB(["listManagers"],lm);
		}
	}
	
	// Update a specific listManager
	function updateListManager(category,lm)
	{
		if (data.version > 1)
		{
			updateDB(["listManagers",category],lm);
		}
	}

	function getListManEntry(database,categoryID,entryID)
	{
		const listMan = database.listManagers[categoryID];
		if (!listMan) { console.error("No list manager for category",categoryID); return null; }
		const target = listMan.find(o=>o.id===entryID);
		if (!target) { console.error("Can't find listManager entry",entryID); return null; }
		return target;
	}

	function getEntryName(database,categoryID,entryID)
	{
		if (entryID === "rootOrder") { return "root";}
		const entry = database[categoryID][entryID];
		if (!entry) { return "NOT FOUND "+categoryID+" "+entryID;}
		return entry.name || "unnnamed "+entryID;
	}

	function duplicateEntry(entry,categoryID)
	{
		const newData = {...data};
		const theOriginalEntry = newData[categoryID][entry.id];
		const theDuplicateEntry = JSON.parse(JSON.stringify(theOriginalEntry));
		// It gets its own ID
		theDuplicateEntry.id = generateUniqueID();
		theDuplicateEntry.name = "Copy of "+theDuplicateEntry.name;
		// And re-ID the notes. Technically, they shouldn't be accessed by ID from anywhere other than this parent, 
		// but it's always good to prevent duplicate IDs
		// const newNotes = {};
		// if (theDuplicateEntry.notes)
		// {
		// 	Object.values(theDuplicateEntry.notes).forEach((note)=>{
		// 		note.id = generateUniqueID();
		// 		newNotes[note.id] = note;
		// 	});
		// 	theDuplicateEntry.notes = newNotes;	
		// }
		// // Add this duplicate entry to the matching database
		newData[categoryID][theDuplicateEntry.id] = theDuplicateEntry;

		// Spawn in as sibling of original
		const listMan = newData.listManagers[categoryID];
		const origListManEntry = listMan.find(o => o.id === entry.id);
		const parentOfTheOrig = listMan.find(o => o.id === origListManEntry.parentID);
		const newListManEntryForDuplicate = { id: theDuplicateEntry.id, parentID: origListManEntry.parentID };

		// Find the index of the original entry in listMan
		const origIndex = listMan.findIndex(o => o.id === entry.id);

		// Insert the new entry immediately after the original entry in listMan
		newData.listManagers[categoryID].splice(origIndex + 1, 0, newListManEntryForDuplicate);

		// Find the index of the original entry in the parent's children array
		const childIndex = parentOfTheOrig.children.indexOf(entry.id);

		// Insert the duplicate entry's ID immediately after the original entry in the children array
		parentOfTheOrig.children.splice(childIndex + 1, 0, theDuplicateEntry.id);

		// Since we changed both the category list and the listmanager, reset the whole db in one whack
		const reopenEntry = { "categoryID": categoryID, "entryID": theDuplicateEntry.id };

		updateDB(null,newData,reopenEntry);
	}

	function deleteEntry(entry,categoryID)
	{
		const nm = entry.name || "entry";
			let message = "Delete "+nm+" from "+categoryID+"?\n";
			Object.entries(entry).forEach(([key, value]) => {
				if (key === "id") { return; }
				if (key === "name") { return; }
				if (key === "notes") { message += Object.entries(entry[key]).length+" notes\n"; return;}
				message += `${key}: ${value}\n`;
			})
			if (confirm(message))
			{
				const newData = {...data};
				const deletedListMan = getListManEntry(newData,categoryID,entry.id);
				// If it has children, elevate them all up to its level
				const parentOfTheDeleted = getListManEntry(newData,categoryID,deletedListMan.parentID);
				// Mpve the children of the deleted up into the parent
				if (deletedListMan.children)
				{
					deletedListMan.children.forEach((child) => 
					{
						console.log(getEntryName(newData,categoryID,child)+" is now child of "+getEntryName(newData,categoryID,parentOfTheDeleted.id))
						// Find the child's entry in the listMan
						const childEntry = getListManEntry(newData,categoryID,child);
						childEntry.parentID = deletedListMan.parentID;
						parentOfTheDeleted.children.push(child)
					});
				}
				// And pull the deleted entry from its parent
				parentOfTheDeleted.children = parentOfTheDeleted.children.filter(item => item !== entry.id);
				// And remove the entry from its category
				delete newData[categoryID][entry.id];
				// Since we changed both the category list and the listmanager, reset the whole db in one whack
				const reopenEntry = { "categoryID": categoryID, "entryID": undefined };
				updateDB(null,newData,reopenEntry);
			}
	}

	// Save the current state of the database to the server
    const save = async () => {
        try {
            const response = await fetch(`${API_URL}/api/data?filename=${filename}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data,null,'\t'),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            // console.log('Save successful:', result);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <DatabaseContext.Provider value={{ 
			data, 
			editMode, 
			currentEntry, 
			isPortrait,
			updateDB, 
			save, 
			updateListManager,
			updateListManagers,
			getPropertyByPath, 
			updateValueByPath,
			setEditMode, 
			addEntry,
			addMention,
			addEncounter,
			addMonsterToEncounter,
			addRollToMonster,
			addExistingRollToMonster,
			addRollsAndNotesToMonster,
			removeRollFromMonster,
			setCurrentEntry,
			deleteEntry,
			duplicateEntry
		}}>
            {children}
        </DatabaseContext.Provider>
    );
};
