import React, { useRef, useEffect, useState, useContext } from 'react';
import NumberModal from '../EncounterModal/NumberModal';
import StatBlock from '../StatBlock/StatBlock.jsx';
import RollArray from '../DiceRenderer/RollArray.jsx';
import ButtonDelete from '../Buttons/ButtonDelete.jsx';
import { propertyDefs } from '../../PropertyDefs.js';
import { rollInitiative } from '../StatBlock/silcerUtils.jsx';

function CharacterButton({chardef,db,encounter,setEncounter,index,removeCharacter,characters})
{
	// Character at index 0 is the top of the turn order, automatically open their stats and roll panel

	const [numberDef, setNumberDef] = useState({ min: 0, max: 10, value: 5, chardef:null });
	const [showModal, setShowModal] = useState(false);
	const [openCharacters, setOpenCharacters] = useState([]);
	
	const handleConfirm = (newValue,numberDef) => {
		// Overwrite that value in a copy of the encounter
		const newEncounterDef = {...encounter};
		const targetCharacter = newEncounterDef.characters.find(o=>o.id===numberDef.chardef.id);
		if (!targetCharacter) { console.log("Can't find targetCharacter",numberDef); return; }
		const targetStat = numberDef.stat;
		targetCharacter[targetStat] = newValue;
		setEncounter(newEncounterDef);
		setShowModal(false);
		// TODO: Option to Save to DB. Saving will etch the current state into the db; otherwise, it only lives in the browser store
	};

	const handleCancel = () => {
		setShowModal(false);
	};
	
	function jumpToCharacter(charDef)
	{
		const instruction = {categoryID:"monsters",entryID:charDef.monster};
		db.setCurrentEntry(instruction);
	}

	function removeCharFromEncounter(chardef)
	{
		const message = "Remove "+chardef.name+" from encounter?";
		if (confirm(message))
		{
			removeCharacter(chardef);
		}
	}

	function deleteButton(chardef)
	{
		return (
			<ButtonDelete onClick={()=>removeCharFromEncounter(chardef)} label={'Remove'}/>
		)
	}

	function includeRollButton(stat,roll)
	{
		// If provided with a stat (like init), roll it
		if (roll === undefined || roll === null) { return null; }
		if (encounter.currentRound != 0) { return null; }
		return <button className='inline-roll-button' onClick={(e)=>{rollInline(e,stat,roll)}}>{"↷"}</button>
	}
	
	function inlineStat(chardef,stat,type,max,roll,label) {
		const curval = chardef[stat];
		const pct = max ? (curval/max).toLocaleString("en", {style: "percent"}) : undefined;
 		const className = "inline-stat-button"+(type==="text"?" inline-stat-button-note":"")
		if (type === "text")
		{
			return (
				<div className={className} onClick={()=>editTextStat(stat,chardef)}>
					<div className='inline-stat-text'>{curval}</div>
				</div>
			)
		}

		return (
			<div className={className} onClick={()=>editStat(stat,chardef)}>
				{includeRollButton(stat,roll)}
				<div className='inline-stat-label'>{label || stat}</div>
				<div className='inline-stat-label'>{curval}</div>
				{pct && <div className='inline-stat-label-mini'>{pct}</div>}
			</div>
		)
	}

	function rollInline(event,stat,mod)
	{
		event.stopPropagation();
		let roll;
		if (db.data.gameSystem == "silcer")
		{
			// Roll highest Action skill
			roll = rollInitiative(chardef.monster, db);
		}
		else
		{
			roll = Math.floor(Math.random() * 20) + 1; // It's 1-based, not 0
			roll += mod;
		}

		const newEncounterDef = { ...encounter };
		const targetCharacter = newEncounterDef.characters.find(o=>o.id===chardef.id);
		if (!targetCharacter) { console.log("Can't find targetCharacter",chardef); return; }
		targetCharacter[stat] = roll;
		setEncounter(newEncounterDef);
	}

	const monster = db.data["monsters"][chardef.monster];

	function editEncounterCharacterName(event,chardef)
	{
		event.stopPropagation();
		editTextStat("name",chardef,monster.name);
	}

	function selectCharacter(chardef) 
	{
		setOpenCharacters(openCharacters => 
		{
			const current = openCharacters || [];
		
			if (current.includes(chardef.id)) {
				// If chardef.id is in the array, filter it out
				return current.filter(id => id !== chardef.id);
			} else {
				// Otherwise, add it to the array
				return [...current, chardef.id];
			}
		});
	}

	function characterSheet(chardef,autoExpand)
	{
		// Find the monster in the db
		const monster = db.data["monsters"][chardef.monster];
		if (!monster) { console.error("Can't find monster",chardef); return; }
		const diceArray = monster.rolls;
		const statBlockPathObj = 
		{
			path:["monsters",monster.id],
			propertyID:"statblock"
		}
		const rollPathObj =
		{
			path:["monsters",monster.id],
			propertyID:"rolls"
		}

		const propDef = propertyDefs.monsterDefProperties.find(o=>o.id==="rolls");
		
		return (
			<div id="character-sheet" className="character-sheet">
				<StatBlock pathObj={statBlockPathObj} gameSystem={db.data.gameSystem} sendResultToHistory={true} autoExpand={autoExpand} characters={characters} />
				{db.data.gameSystem != "silcer" && <RollArray rollArray={diceArray} character={monster} sendResultToHistory={true} autoExpand={autoExpand} pathObj={rollPathObj} propDef={propDef}/>}
			</div>
		)
	}

	function editStat(stat,chardef)
	{
		const defaultValue = monster.statblock[stat] || monster.statblock.resiliences[stat];
		const numberDef = { defaultValue:defaultValue, min: 0, max: 999, value: chardef[stat], stat:stat, chardef:chardef };
		setNumberDef(numberDef);
		setShowModal(true);
	}
	
	function editTextStat(stat,chardef,defaultValue)
	{
		const numberDef = { defaultValue:defaultValue, type:"text", min: 0, max: 999, value: chardef[stat], stat:stat, chardef:chardef };
		setNumberDef(numberDef);
		setShowModal(true);
	}

	function characterName()
	{
		if (!monster) return;
		
		// If they have a custom name, include the monster's original name in subscript
		const encName = chardef.name;
		const monsterName = monster.name;
		return (
			<div className='button-character-row'>
				<div className='button-character-encounter-name'>{encName}</div>
				{(encName != monsterName) && <div className='button-character-sub'>({monsterName})</div>}
			</div>
		)
	}

	const instanceVars = () => {
		if (db.data.gameSystem == "silcer") {
			return {
				init: { value: chardef.init, label: "Init", id: "init", max: null, roll: 'init' },
				integrity: { value: chardef.integrity, label: "Int", id: "integrity", max: monster.statblock.resiliences.integrity },
				focus: { value: chardef.focus, label: "Foc", id: "focus", max: monster.statblock.resiliences.focus },
				standing: { value: chardef.standing, label: "Sta", id: "standing", max: monster.statblock.resiliences.standing },
			};
		} else {
			// Regular D&D stats
			return {
				init: { value: chardef.init, label: "Init", id: "init", max: null, roll: monster?.statblock['init'] },
				hp: { value: chardef.hp, label: "HP", id: "hp", max: chardef.maxhp},
				ac: { value: chardef.ac, label: "AC", id: "ac" },
			};
		}
	};

	const stats = instanceVars();

	const hiddenish = stats.hp?.value !== undefined && stats.hp.value <= 0 ? "greyed-out" : "";

	return (
		<div className='container-character'>

			{showModal && (
				<NumberModal 
					numberDef={numberDef} 
					onConfirm={handleConfirm} 
					onCancel={handleCancel}
				/>
			)}

			<div className={'container-character-row '+hiddenish}>
				
				<div className='button-character' 
					onClick={() => selectCharacter(chardef)}
				>
					<button className='button-jump-to-character' onClick={() => jumpToCharacter(chardef)}>{"↫"}</button>
					{characterName()}
					<div 
						className='button-character-name' 
						onClick={(e) => editEncounterCharacterName(e,chardef)}
					>
						{"✎"}
						</div>
					</div>
				<div className='container-character-row-dynamic-stats'>
					{Object.entries(stats).map(([key, stat]) =>
						stat.value !== undefined && inlineStat(chardef, stat.id, "number", stat.max, stat.roll, stat.label)
					)}
				</div>
				{inlineStat(chardef,"note","text")}
				{db.editMode && deleteButton(chardef)}
			</div>
			{(openCharacters.includes(chardef.id) || index === 0) && characterSheet(chardef,index === 0)}
		</div>
	)
}
export default CharacterButton;