import React, { useState } from "react";
import "./AugmentPicker.css"; // Reusing the editor's stylesheet
import { titleCase } from "./silcerUtils";
import { useDatabase } from "../../DatabaseProvider";
import LabelModal from "../EncounterModal/LabelModal";

const AugmentsRow = ({ statblock, augmentsData }) => {
    const db = useDatabase();
    const [showModal, setShowModal] = useState(false);
    const [labelDef, setLabelDef] = useState({});

    if (!statblock.augments || statblock.augments.length === 0) {
        return <div className="augment-row">No Augments</div>;
    }

    // Map augment IDs to actual augment objects
    const augments = statblock.augments
        .map((id) => augmentsData[id]) // Fetch augment by ID
        .filter((augment) => augment); // Filter out any undefined values

    const typeClassMap = {
        passive: "augment-button-passive",
        active: "augment-button-active",
        quantum: "augment-button-quantum",
    };

    const clickAugment = (augmentID) => {
        if (db.editMode == false)
        {
            setLabelDef({ label:buildAugmentPopup(augmentID) });
            setShowModal(true);
        }
    }

    const buildAugmentPopup = (augmentID) => {
        let augment = db.data.augments[augmentID];
        console.log(augmentID, augment);
        if (!augment) {
            console.log(`Can't find augment ${augmentID}`);
            return null; // Return null to avoid rendering anything
        }

        const { name, desc, rank, target, duration, skill, type } = augment;

        return (
            <>
                <div className="label-display">
                    <div className="label-title">{name}</div>
                    {<div>{titleCase(type)}</div>}
                    {` (+${rank} ${titleCase(skill)}${type === "quantum" ? ` / Target: ${titleCase(target)}` : ""}${duration ? ` / ${titleCase(duration)}` : ""})`}
                </div>
                <div>{desc}</div>
            </>
        );
    };


    return (
        <div className="augment-row">
            {showModal && (
                <LabelModal
                    labelDef={labelDef} 
                    onCancel={() => setShowModal(false)}
                />
            )}
            {augments.map((augment) => {
                const buttonClass = `augment-button ${typeClassMap[augment.type] || ""}`;
                let nameclass = "augment-name";
                if (augment.illicit == "illicit") nameclass += " augment-illicit";
                if (augment.illicit == "magnichor") nameclass += " augment-magnichor";
                
                return (
                    <button
                        className={buttonClass}
                        key={augment.id}
                        onClick={() => clickAugment(augment.id)}
                    >
                        <div className={nameclass}>{augment.name}</div>
                        <div className="augment-desc">
                            +{augment.rank} {titleCase(augment.skill)} ({titleCase(augment.type)})
                        </div>
                        <div className="augment-description">{augment.description}</div>
                    </button>
                );
            })}
        </div>
    );

};

export default AugmentsRow;
